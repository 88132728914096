import React, { useState } from "react";
import { motion } from "framer-motion";
import emailjs from "emailjs-com";
import contact from "../assets/contact.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactComponent = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const userId = "PuLc-4b96ZeAqEo1h"; // Replace with your actual Email.js user ID
      const serviceId = "service_bed9n9p";
      const templateId = "template_ufu5y3n";

      // Initialize the emailjs library with the user ID
      emailjs.init(userId);

      // Send email using Email.js
      await emailjs.send(serviceId, templateId, formData);

      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });

      toast.success("Email was sent successfully!", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.log("Email sent successfully!");
    } catch (error) {
      toast.error(`Email was not sent successfully`, {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error("Error sending email:", error);
    }
  };

  return (
    <motion.section
      className="bg-teald bg-opacity-20 font-primary lg:min-h-screen lg:flex lg:justify-center lg:items-center lg:py-0 py-12 dark:bg-searchDark"
      id="contact"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <ToastContainer position="top-center" />
      <div>
        <div className="mx-auto mb-8 max-w-screen-xl lg:mb-8 text-center">
          <motion.h2
            className="mb-4 text-2xl tracking-tight font-extrabold text-center text-gray-900  md:text-4xl xl:text-5xl dark:text-darkText"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5 }}
          >
            Contact Us
          </motion.h2>
          <motion.p
            className="font-normal text-gray-500 lg:text-lg px-2 lg:px-0"
            initial={{ opacity: 0, y: -150 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5 }}
          >
            We would love to hear from you, so please don't hesitate to reach
            out to us with any inquiries, questions, or collaboration
            opportunities..
          </motion.p>
        </div>
        <motion.div
          className="gap-8 items-center px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.5 }}
        >
          <motion.img
            className="w-full "
            src={contact}
            alt="img"
            whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
          />
          <motion.div
            className="mt-4 md:mt-0"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5 }}
          >
            <form className="space-y-8" onSubmit={handleSubmit}>
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.7 }}
              >
                <label
                  htmlFor="name"
                  className="block mb-2 text-lg font-medium text-gray-900 dark:text-darkText"
                >
                  Your Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="shadow-lg bg-gray-50 text-md text-gray-900 rounded-lg block w-full p-2.5 dark:focus:outline-none dark:focus:ring focus:border-buttons dark:focus:border-teald dark:bg-backgroundDark dark:text-darkText "
                  placeholder="Your name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.8 }}
              >
                <label
                  htmlFor="email"
                  className="block mb-2 text-lg font-medium text-gray-900 dark:text-darkText"
                >
                  Your email
                </label>
                <input
                  type="email"
                  id="email"
                  className="shadow-lg bg-gray-50 text-gray-900 text-md rounded-lg block w-full p-2.5 dark:focus:outline-none dark:focus:ring focus:border-buttons dark:focus:border-teald dark:bg-backgroundDark dark:text-darkText dark:placeholder:bg-backgroundDark dark:placeholder:text-darkText"
                  placeholder="name@example.com"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.9 }}
              >
                <label
                  htmlFor="subject"
                  className="block mb-2 text-lg font-medium text-gray-900 dark:text-darkText"
                >
                  Your Phone
                </label>
                <input
                  type="number"
                  id="phone"
                  className="shadow-lg bg-gray-50 text-gray-900 text-md rounded-lg block w-full p-2.5 dark:focus:outline-none dark:focus:ring focus:border-buttons dark:focus:border-teald dark:bg-backgroundDark dark:text-darkText"
                  placeholder="Phone number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </motion.div>
              <motion.div
                className="sm:col-span-2"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 1 }}
              >
                <label
                  htmlFor="message"
                  className="block mb-2 text-lg font-medium text-gray-900 dark:text-darkText"
                >
                  Your message
                </label>
                <textarea
                  id="message"
                  rows="6"
                  className="block p-2.5 w-full text-md text-gray-900 bg-gray-50 rounded-lg shadow-lg dark:focus:outline-none dark:focus:ring focus:border-buttons dark:focus:border-teald dark:bg-backgroundDark dark:text-darkText"
                  placeholder="Leave a comment..."
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </motion.div>
              <motion.button
                type="submit"
                className="py-3 px-5 text-md font-medium text-center shadow-xl text-white rounded-lg bg-buttons sm:w-fit hover:bg-buttons focus:ring-4 focus:outline-none focus:ring-buttons dark:bg-teald dark:hover:bg-teald dark:focus:ring-teald"
                whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
              >
                Send message
              </motion.button>
            </form>
          </motion.div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default ContactComponent;

import React, { useState } from "react";
import logo from "../assets/wad_transp.png";
import logoL from "../assets/wad_white.png";
import { HiOutlineBars3 } from "react-icons/hi2";
import { IoCloseOutline } from "react-icons/io5";
import { MdOutlineLightMode, MdDarkMode } from "react-icons/md";
import { motion } from "framer-motion";
import { Link } from "react-scroll";

const Navbar = ({ changeTheme, theme }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <motion.header
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <motion.nav
        className="fixed top-0 w-full lg:px-4 px-6 py-2 z-50 lg:shadow-none shadow-md bg-white dark:bg-backgroundDark"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="flex justify-between items-center mx-auto lg:max-w-screen-2xl">
          <a href="/" className="flex items-center">
            <img
              src={theme === "dark" ? logoL : logo}
              className="lg:w-52 w-32"
              alt="wad Logo"
            />
          </a>
          <div className="flex items-center">
            <button
              onClick={changeTheme}
              className="block font-primary text-lg lg:hidden py-2 px-2 text-gray-700 font-bold hover:bg-gray-50 dark:hover:bg-searchDark lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 cursor-pointer dark:text-darkText"
            >
              {theme === "dark" ? (
                <MdOutlineLightMode size={24} />
              ) : (
                <MdDarkMode size={24} />
              )}
            </button>
            <button
              onClick={toggleMobileMenu}
              type="button"
              className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-darkText dark:bg-backgroundDark dark:focus:ring-backgroundDark"
              aria-expanded={isMobileMenuOpen ? "true" : "false"}
              aria-controls="mobile-menu-2"
            >
              <HiOutlineBars3
                className={`w-6 h-6 dark:bg-backgroundDark dark:text-darkText ${
                  isMobileMenuOpen ? "hidden" : ""
                }`}
              />
              <IoCloseOutline
                className={`w-6 h-6 dark:bg-backgroundDark dark:text-darkText ${
                  isMobileMenuOpen ? "" : "hidden"
                }`}
              />
            </button>
          </div>
          <motion.div
            className={`justify-between items-center w-auto max-h-fit lg:flex lg:w-auto lg:order-1 ${
              isMobileMenuOpen
                ? "block fixed inset-y-20 inset-x-0 z-50 bg-white dark:bg-backgroundDark"
                : "hidden"
            }`}
            id="mobile-menu-2"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:gap-8 lg:mt-0">
              <li>
                <Link
                  to="home"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={closeMobileMenu}
                  className="font-primary block text-lg py-2 px-2 text-gray-700 font-bold border-b dark:border-searchDark hover:bg-gray-50 dark:hover:bg-searchDark lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 cursor-pointer dark:text-darkText"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="about"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={closeMobileMenu}
                  className="font-primary text-lg block py-2 px-2 text-gray-700 font-bold border-b dark:border-searchDark hover:bg-gray-50 dark:hover:bg-searchDark lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 cursor-pointer dark:text-darkText"
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="service"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={closeMobileMenu}
                  className="font-primary text-lg block py-2 px-2 text-gray-700 font-bold border-b dark:border-searchDark hover:bg-gray-50 dark:hover:bg-searchDark lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 cursor-pointer dark:text-darkText"
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="team"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={closeMobileMenu}
                  className="font-primary text-lg block py-2 px-2 text-gray-700 font-bold border-b dark:border-searchDark hover:bg-gray-50 dark:hover:bg-searchDark lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 cursor-pointer dark:text-darkText"
                >
                  Team
                </Link>
              </li>
              <li>
                <Link
                  to="partners"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={closeMobileMenu}
                  className="font-primary text-lg block py-2 px-2 text-gray-700 font-bold border-b dark:border-searchDark hover:bg-gray-50 dark:hover:bg-searchDark lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 cursor-pointer dark:text-darkText"
                >
                  Partners
                </Link>
              </li>
              <li>
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={closeMobileMenu}
                  className="font-primary text-lg block py-2 px-2 text-gray-700 font-bold border-b dark:border-searchDark hover:bg-gray-50 dark:hover:bg-searchDark lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 cursor-pointer dark:text-darkText"
                >
                  Contact
                </Link>
              </li>
              <li>
                <button
                  onClick={changeTheme}
                  className="hidden font-primary text-lg lg:block py-2 px-2 text-gray-700 font-bold border-b dark:border-searchDark hover:bg-gray-50 dark:hover:bg-searchDark lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 cursor-pointer dark:text-darkText"
                >
                  {theme === "dark" ? (
                    <MdOutlineLightMode size={24} />
                  ) : (
                    <MdDarkMode size={24} />
                  )}
                </button>
              </li>
            </ul>
          </motion.div>
        </div>
      </motion.nav>
    </motion.header>
  );
};

export default Navbar;

import React from "react";
import { motion } from "framer-motion";
import willy from "../assets/willy.png";
import diddy from "../assets/diddy.png";
import frank from "../assets/frank.png";
import { BsTwitterX } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa6";

const TeamComponent = () => {
  const teamMembers = [
    {
      name: "William Ngizwenayo",
      role: "CEO/Co-founder",
      image: willy,
      twitter: "https://twitter.com/ngizwewilliam",
      linkedin: "https://www.linkedin.com/in/william-ngizwenayo-448816ab/",
    },
    {
      name: "Didier Usanase",
      role: "CTO/Co-founder",
      image: diddy,
      twitter: "https://twitter.com/usanaself",
      linkedin: "https://www.linkedin.com/in/didier-usanase-25a008103/",
    },
    {
      name: "Frank Ntwari",
      role: "C00/Co-founder",
      image: frank,
      twitter: "https://twitter.com/Franko07763874",
      linkedin: "https://www.linkedin.com/in/frank-ntwari-6551aa230/",
    },
  ];

  return (
    <motion.section
      className="bg-background bg-opacity-30 font-primary lg:min-h-screen lg:flex lg:justify-center lg:items-center dark:bg-searchDark"
      id="team"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
        <div className="mx-auto mb-8 max-w-screen-xl lg:mb-16">
          <motion.h2
            className="mb-4 text-2xl tracking-tight font-extrabold text-gray-900 md:text-4xl xl:text-5xl dark:text-darkText"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            Our Team
          </motion.h2>
          <motion.p
            className="font-normal text-gray-500 lg:text-lg"
            initial={{ opacity: 0, y: -150 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            Meet the brilliant minds behind WAD! Our dynamic team is on a
            mission to revolutionize the healthcare industry. Together, we're
            building a future filled with innovation and endless possibilities.
          </motion.p>
        </div>
        <motion.div
          className="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1.5 }}
        >
          {teamMembers.map((member, index) => (
            <motion.div
              key={index}
              className="text-center text-gray-500"
              initial={{ opacity: 0, scale: 0.8, x: -50 }}
              whileInView={{ opacity: 1, scale: 1, x: 0 }}
              transition={{ duration: 1.5 }}
            >
              <motion.img
                className="mx-auto mb-4 w-36 h-36 lg:w-48 lg:h-48 shadow-2xl border-8 border-buttons dark:bg-teald border-opacity-25 rounded-full"
                src={member.image}
                alt={`team-${index}`}
                whileHover={{ scale: 1.3, transition: { duration: 0.3 } }}
              />
              <motion.div
                className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-darkText"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 1.5 + index * 0.2 + 0.2 }}
              >
                <h4>{member.name}</h4>
              </motion.div>
              <motion.p
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 1.5 + index * 0.2 + 0.4 }}
              >
                {member.role}
              </motion.p>
              <ul className="flex justify-center mt-4 space-x-4">
                <motion.li
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 1.5 + index * 0.2 + 0.6 }}
                >
                  <a
                    href={member.twitter}
                    className="text-gray-500 hover:text-gray-900"
                  >
                    <BsTwitterX size={20} />
                  </a>
                </motion.li>
                <motion.li
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 1.5 + index * 0.2 + 0.8 }}
                >
                  <a
                    href={member.linkedin}
                    className="text-gray-500 hover:text-gray-900"
                  >
                    <FaLinkedin size={22} />
                  </a>
                </motion.li>
              </ul>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </motion.section>
  );
};

export default TeamComponent;

import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import { motion } from "framer-motion";

const data = [
  { label: "Pharmacies", count: 24 },
  { label: "Distributors", count: 16 },
  { label: "Orders", count: 50 },
  { label: "Medications", count: "11000" },
];

const Achievement = () => {
  const [counts, setCounts] = useState({});

  // Add useEffect to update counts when the component mounts
  useEffect(() => {
    const initialCounts = {};
    data.forEach(({ label, count }) => {
      initialCounts[label] = count;
    });
    setCounts(initialCounts);
  }, []);

  return (
    <section className="bg-buttons bg-opacity-20 dark:bg-backgroundDark font-primary">
      <motion.h1
        className="font-primary text-center mb-4 text-2xl pt-8 lg:pt-16 font-extrabold tracking-tight leading-none md:text-4xl xl:text-5xl dark:text-darkText "
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.5 }}
      >
        Our Achievements
      </motion.h1>
      <motion.div
        className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6"
        iinitial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 1.5 }}
      >
        <dl className="grid max-w-screen-md gap-8 mx-auto text-gray-900 lg:grid-cols-4 dark:text-white">
          {data.map(({ label, index }) => (
            <motion.div
              key={index}
              className="flex flex-col items-center justify-center"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 1.5 + index * 0.2 + 0.4 }}
            >
              <dt className="mb-2 text-3xl md:text-4xl font-extrabold">
                {label === "Medications" ? (
                  <>
                    <CountUp end={counts[label]} />
                    <span className="text-4xl">+</span>
                  </>
                ) : (
                  <CountUp end={counts[label]} />
                )}
              </dt>
              <dd className="font-normal text-gray-500 dark:text-gray-400">
                {label}
              </dd>
            </motion.div>
          ))}
        </dl>
      </motion.div>
    </section>
  );
};

export default Achievement;

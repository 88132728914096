import React from "react";
import { motion } from "framer-motion";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io5";

const FooterComponent = () => {
  return (
    <motion.footer
      className="p-4 bg-white lg:p-6 font-primary dark:bg-backgroundDark"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <hr className=" border-gray-200 sm:mx-auto my-4" />
      <div className="mx-auto">
        <motion.div
          className="flex lg:flex-row items-center justify-center lg:gap-8 flex-col gap-2 "
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          <span className="text-sm text-gray-500 sm:text-center">
            © 2024{" "}
            <a href="/" className="hover:underline">
              WAD Pharma™
            </a>
            . All Rights Reserved.
          </span>{" "}
          <motion.ul
            className="text-gray-500 text-sm flex justify-center items-center gap-6"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.8 }}
          >
            <motion.li
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 1 }}
            >
              <a href="/" className="hover:underline">
                Privacy Policy
              </a>
            </motion.li>
            <motion.li
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 1.2 }}
            >
              <a href="/" className="hover:underline">
                Terms &amp; Conditions
              </a>
            </motion.li>
          </motion.ul>
          <motion.div
            className="flex mt-4 space-x-6 sm:justify-center sm:mt-0"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 1.5 }}
          >
            <motion.a
              href="https://twitter.com/wadpharma"
              target="_blank"
              rel="noreferrer"
              className="text-gray-500 hover:text-gray-900"
              whileHover={{ scale: 1.2, transition: { duration: 0.3 } }}
            >
              <BsTwitterX size={20} />
            </motion.a>
            <motion.a
              href="https://facebook.com/wadpharma"
              target="_blank"
              rel="noreferrer"
              className="text-gray-500 hover:text-gray-900"
              whileHover={{ scale: 1.2, transition: { duration: 0.3 } }}
            >
              <FaFacebook size={20} />
            </motion.a>
            <motion.a
              href="https://linkedin.com/company/wadpharma"
              target="_blank"
              rel="noreferrer"
              className="text-gray-500 hover:text-gray-900"
              whileHover={{ scale: 1.2, transition: { duration: 0.3 } }}
            >
              <FaLinkedin size={20} />
            </motion.a>
            <motion.a
              href="https://instagram.com/wad_pharma"
              target="_blank"
              rel="noreferrer"
              className="text-gray-500 hover:text-gray-900"
              whileHover={{ scale: 1.2, transition: { duration: 0.3 } }}
            >
              <FaInstagram size={20} />
            </motion.a>
            <motion.a
              href="https://youtube.com/@wadpharma"
              target="_blank"
              rel="noreferrer"
              className="text-gray-500 hover:text-gray-900"
              whileHover={{ scale: 1.2, transition: { duration: 0.3 } }}
            >
              <IoLogoYoutube size={20} />
            </motion.a>
          </motion.div>
        </motion.div>
      </div>
    </motion.footer>
  );
};

export default FooterComponent;

import React from "react";
import { Dialog } from "@headlessui/react";
import { motion } from "framer-motion";

function MyDialog({ content, openDialog, onClose }) {
  return (
    <Dialog open={openDialog} onClose={onClose} className="relative z-50">
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <motion.div
        className="fixed inset-0 bg-black/50"
        aria-hidden="true"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      />

      {/* Full-screen scrollable container */}
      <div className="fixed inset-0 w-screen overflow-y-auto">
        {/* Container to center the panel */}
        <motion.div
          className="flex min-h-screen items-center justify-center p-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.3 }}
        >
          {/* The actual dialog panel  */}
          <Dialog.Panel className=" lg:p-8 p-2 px-4 mx-auto max-w-5xl rounded bg-white dark:bg-backgroundDark dark:text-darkText overflow-y-auto lg:max-h-screen max-h-96 font-primary">
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.5 }}
            >
              <Dialog.Title className="text-center mb-2 text-xl py-2 font-extrabold tracking-tight leading-none md:text-3xl xl:text-4xl">
                {content.title}
              </Dialog.Title>

              <div className="lg:grid lg:grid-cols-2 lg:place-items-center flex flex-col justify-center items-center pb-8">
                <div>
                  <img src={content.img} className="lg:w-72 w-52" alt="img" />
                </div>
                <Dialog.Description className="text-lg">
                  {content.desc}
                </Dialog.Description>
              </div>

              <div>
                {content.text &&
                  content.text.split("\n").map((str, index) => (
                    <p key={index} className="lg:p-4 p-2 text-lg">
                      {str}
                    </p>
                  ))}
                {content.features && (
                  <h5 className="text-2xl font-bold">
                    {content.features.title}
                  </h5>
                )}
                {content.features &&
                  content.features.text.split("\n").map((str, index) => (
                    <p key={index} className="lg:p-4 p-2 text-lg">
                      {str}
                    </p>
                  ))}
                {content.benefits && (
                  <h5 className="text-2xl font-bold">
                    {content.benefits.title}
                  </h5>
                )}
                {content.benefits &&
                  content.benefits.text.split("\n").map((str, index) => (
                    <p key={index} className="lg:p-4 p-2 text-lg">
                      {str}
                    </p>
                  ))}
              </div>
            </motion.div>
          </Dialog.Panel>
        </motion.div>
      </div>
    </Dialog>
  );
}

export default MyDialog;

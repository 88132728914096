import React from "react";
import { motion } from "framer-motion";
import foundation from "../assets/foundation.jpg";
import jasiri from "../assets/jasiri.png";

const PartnerComponent = () => {
  const partners = [
    { name: "Jasiri", image: jasiri },
    { name: "Foundation", image: foundation },
    // Add more partners if needed
  ];

  return (
    <motion.section
      className="bg-opacity-25 font-primary lg:pt-16 lg:flex lg:justify-center lg:items-center items-center dark:bg-backgroundDark"
      id="partners"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
        <div className="mx-auto mb-8 max-w-screen-xl lg:mb-16">
          <motion.h2 className="mb-4 text-2xl tracking-tight font-extrabold text-gray-900 md:text-4xl xl:text-5xl dark:text-darkText">
            Our Partners
          </motion.h2>
        </div>
        <motion.div
          className="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          {partners.map((partner, index) => (
            <motion.div
              key={index}
              className="text-center text-gray-500"
              initial={{ opacity: 0, scale: 0.8, x: -50 }}
              whileInView={{ opacity: 1, scale: 1, x: 0 }}
              transition={{ duration: 1, delay: 0.6 + index * 0.2 }}
            >
              <motion.img
                className="mx-auto mb-4 w-36 h-36 lg:w-48 lg:h-48"
                src={partner.image}
                alt={`partner-${index}`}
                whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
              />
            </motion.div>
          ))}
        </motion.div>
      </div>
    </motion.section>
  );
};

export default PartnerComponent;
